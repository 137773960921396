//权限管理
export const userRouter = [
  {
    path: "/role-manage",
    name: "role-manage",
    component: () => import("@/views/user/role-manage"),
  },
  {
    path: "/dept-manage",
    name: "dept-manage",
    component: () => import("@/views/user/dept-manage"),
  },
  {
    path: "/user-manage",
    name: "user-manage",
    component: () => import("@/views/user/user-manage"),
  },
  {
    path: "/customer-manage/:type",
    name: "customer-manage",
    component: () => import("@/views/user/customer-manage"),
  },
  {
    path: "/cdz-index",
    name: "cdz-index",
    component: () => import("@/views/cdz-index"),
  },
];

export const staticRoutes = [
  //项目管理
  {
    path: "/pm-index",
    name: "pm-index",
    meta: {
      key: "pm-index",
    },
    component: () => import("@/views/pm/pm-index"),
  }, //设备管理
  {
    path: "/equipmentCentralize",
    name: "equipmentCentralize",
    component: () => import("@/views/equipment/equipmentCentralize"),
  },
  {
    path: "/community-manage",
    name: "community-manage",
    component: () => import("@/views/cdz/community-manage"),
  }, //位置管理
  {
    path: "/position-manage",
    name: "position-manage",
    component: () => import("@/views/cdz/position-manage"),
  },
  {
    path: "/charging-pile",
    name: "charging-pile",
    component: () => import("@/views/equipment/charging-pile"),
  },
  {
    path: "/charging-port",
    name: "charging-port",
    meta: {
      noCache: true,
    },
    props: ({ query }) => {
      return {
        deviceType:query.deviceType,
        deviceKey: query.deviceKey,
        deviceId: query.deviceId,
      };
    },
    component: () => import("@/views/equipment/charging-port"),
  },
  {
    path: "/costs-record",
    name: "costs-record",
    props: ({ query }) => {
      return {
        customerId: query.customerId,
      };
    },
    component: () => import("@/views/user/costs-record"),
  },
  {
    //充值退款查询
    path: "/recharge-hist-record",
    name: "recharge-hist-record",
    props: ({ query }) => {
      return {
        customerId: query.customerId,
      };
    },
    component: () => import("@/views/user/recharge-hist-record"),
  },
  {
    path: "/charge-hist-order",
    name: "charge-hist-order",
    props: ({ query }) => {
      return {
        customerId: query.customerId,
      };
    },
    component: () => import("@/views/user/charge-hist-order"),
  },
  {
    //费用统计查询
    path: "/costs-report",
    name: "costs-report",
    component: () => import("@/views/cdz/costs-report"),
  },
  {
    //费用统计查询
    path: "/operatorcosts-report",
    name: "operatorcosts-report",
    component: () => import("@/views/cdz/operatorcosts-report"),
  },
  {
    //运营实体信息维护
    path: "/dict-manage",
    name: "dict-manage",
    component: () => import("@/views/cdz/dict-manage.vue"),
  },
  {
    //在线卡信息维护
    path: "/card-manage",
    name:"card-manage",
    props: ({ query }) => {
      return {
        customerId: query.customerId,
      };
    },
    component:()=>import("@/views/cdz/card-manage"),
  },
  {
    //充值模板维护
    path:"/template-manage",
    name:"template-manage",
    component : () => import("@/views/cdz/template-manage")
  },
  {
    //代理商充值
    path:"/agent-manage",
    name:"agent-manage",
    component : () => import("@/views/cdz/agent-manage")
  },
  {
    //代理商管理
    path:"/agent-record",
    name:"agent-record",
    meta: {
      noCache: true,
    },
    props:({query})=>{
      return{
        agentId:query.agentId
      }
    },
    component : () => import("@/views/cdz/agent-record")
  },
  {
    //代理商充值
    path:"/agent-recharge",
    name:"agent-recharge",
    component:()=>import("@/views/cdz/agent-recharge")
  },
  {
    //充电模板维护
    path:"/charge-template-manage",
    name:"charge-template-manage",
    component : () => import("@/views/cdz/charge-template-manage")
  },
  {
    //充电模板维护
    path:"/charging-qr",
    name:"charging-qr",
    component : () => import("@/views/equipment/charging-qr")
  },
  {
    //历史账号转移
    path:"/hist-user-manage",
    name:"hist-user-manage",
    component : () => import("@/views/cdz/hist-user-manage")
  },
  {
    //小区费用统计查询
    path: "/communitycosts-report",
    name: "communitycosts-report",
    component: () => import("@/views/cdz/communitycosts-report"),
  },
  {
    //点点转移金额退款
    path: "/diandian-recharge",
    name: "diandian-recharge",
    component: () => import("@/views/cdz/diandian-recharge"),
  },
  {
    //点点转移消费统计
    path: "/diandian-report",
    name: "diandian-report",
    component: () => import("@/views/cdz/diandian-report"),
  },
  {
    //充电中订单查询
    path: "/charge-current-order",
    name: "charge-current-order",
    component: () => import("@/views/cdz/charge-current-order"),
  },
  {
    //历史订单查询
    path: "/charge-hist-order-query",
    name: "charge-hist-order-query",
    component: () => import("@/views/cdz/charge-hist-order-query"),
  },
  {
    //可退款记录查询
    path: "/weixin-recharge",
    name: "weixin-recharge",
    component: () => import("@/views/cdz/weixin-recharge"),
  },
  {
    //异常端口监测
    path: "/charging-fault-port",
    name: "charging-fault-port",
    component: () => import("@/views/equipment/charging-fault-port"),
  },
  {
    //安全信息查询
    path: "/exception-msg-his-query",
    name: "exception-msg-his-query",
    component: () => import("@/views/cdz/exception-msg-his-query"),
  },
  {
    //广告信息管理
    path: "/pic_manager",
    name: "pic_manager",
    component: () => import("@/views/cdz/pic_manager"),
  }
];

export const cdzmRoutes = [
  {
    path: "/monitor-board",
    name: "monitor-board",
    component: () => import("@/views/cdzm/monitor-board"),
    meta: {
      noCache: true,
    },
  },
  {
    path: "/community-board",
    name: "community-board",
    component: () => import("@/views/cdzm/community-board"),
    meta: {
      noCache: true,
    },
  },
  {
    //充电棚监测查询
    path: "/monitor-query",
    name: "monitor-query",
    component: () => import("@/views/cdzm/monitor-query"),
  },
  {
    path: "/monitor-hist-query",
    name: "monitor-hist-query",
    props: ({ query }) => {
      return {
        monitorId: query.monitorId,
        monitorType: query.monitorType,
        monitorItem: query.monitorItem
      };
    },
    component: () => import("@/views/cdzm/monitor-hist-query"),
  },
  {
    //充电棚监测管理
    path: "/monitor-manage",
    name: "monitor-manage",
    component: () => import("@/views/cdzm/monitor-manage"),
  },
  {
    //电动自行车配置模板
    path: "/template-bike",
    name: "template-bike",
    component: () => import("@/views/cdz/template-bike"),
  },
  {
    //分账商户管理
    path: "/share-merchant",
    name: "share-merchant",
    component: () => import("@/views/cdz/share-merchant"),
  },
  {
    //分账商户管理
    path: "/share-setting",
    name: "share-setting",
    component: () => import("@/views/cdz/share-setting"),
  },
  {
    //分账记录查询
    path: "/share-plan",
    name: "share-plan",
    component: () => import("@/views/cdz/share-plan"),
  },
];
