import {createRouter, createWebHashHistory} from 'vue-router'
import index from "@/views/public/ch-index";
import {cdzmRoutes, staticRoutes, userRouter} from "@/router/routes";


const routes = [
    {
        path: '/index',
        name: 'ch-index',
        component: index,
        children: [
            ...userRouter,
            ...staticRoutes,
            ...cdzmRoutes
        ]
    }, {
        path: '/',
        name: 'ch-login',
        component: () => import("@/views/public/ch-login")
    }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
export default router
