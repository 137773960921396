import axios from "axios";
import {message} from "ant-design-vue";
import constant from "@/assets/static";

const service = axios.create({
    baseURL: constant.baseUrl,
    // baseURL: "/api",
    // url = base url + request url
    timeout: 60000, // request timeout
});

//响应拦截
service.interceptors.response.use(
    (response) => {
        let data = response.data;
        if(data instanceof Blob){
            return data;
        }
        if (data.code !== 0) {
            message.error(data.msg);
            return Promise.reject(data.msg);
        }
        return data.data;
    },
    (error) => {
        if (error.response?.data?.code === 401) {
            message.error("请重新登录");
            sessionStorage.clear();
            window.location.href = '/'
        }
        return Promise.reject(error);
    }
);


//请求拦截
service.interceptors.request.use(
    (config) => {
        if (config.url !== '/user/login') {
            let token = sessionStorage.getItem('token');
            if (token) {
                config.headers.token = token;
            } else {
                message.warn("请先登录！")
                sessionStorage.clear();
                window.location.href = '/'
                return false;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default service;

