<template>
  <a-config-provider :locale="zhCN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import {useRouter} from 'vue-router';
import 'dayjs/locale/zh-cn';

dayjs.locale('zh-cn')
useRouter().replace({
  path: '/'
})
</script>

<style lang="less">


#app {
  width: 100%;
  height: 100%;


  > div {
    width: 100%;
    height: 100%;
  }
}
</style>
