import request from "../index";

export default {
    setIndexPage(homePage) {
        return request({
            url: "/user/setIndexPage",
            method: "post",
            data: {
                homePage
            }
        });
    },
    changePassword(data) {
        return request({
            url: "/user/changePassword",
            method: "post",
            data
        });
    },
    setStyle(style) {
        return request({
            url: "/user/setStyle",
            method: "get",
            params: {
                style
            }
        });
    },
    queryUser(param) {
        return request({
            url: "/user/queryUser",
            method: "get",
            params: param
        });
    },
    updateState(param) {
        return request({
            url: "/user/updateState",
            method: "get",
            params: param
        });
    },
    insertUser(param) {
        return request({
            url: "/user/insertUser",
            method: "post",
            data: param
        });
    },
    updateUser(param) {
        return request({
            url: "/user/updateUser",
            method: "post",
            data: param
        });
    },
    deleteUser(userIdList) {
        return request({
            url: "/user/deleteUser",
            method: "post",
            data: userIdList
        });
    },
    queryRole() {
        return request({
            url: "/role/queryAll",
            method: "get",
        });
    },
    queryRegion() {
        return request({
            url: "/region/treeQuery",
            method: "get",
        });
    },
    querybusiness() {
        return request({
            url: "/business/selectAll",
            method: "get",
        });
    },
    permissionSettings(param) {
        return request({
            url: "/user/permissionSettings",
            method: "post",
            data: param
        });
    },
    queryPermission(param) {
        return request({
            url: "/user/queryPermission",
            method: "get",
            params: param
        });
    },
    saveUserOperator(data){
        return request({
           url:"/userOperator/saveUserOperator",
            method:"post",
            data:data
        });
    },
    getUserOperators(param){
        return request({
            url:"/userOperator/getUserOperators",
            method:"post",
            params:param
        })
    },
    resetPassWord(param){
        return request({
            url:"/user/resetPassWord",
            method:"get",
            params:param
        });
    },
    getSubAccountCommunity(param){
        return request({
            url:"/community/subAccountCommunityQuery",
            method:"get",
            params:param
        })
    },
    queryUserCommunityByUserId(param){
        return request({
            url:"/userCommunity/queryUserCommunityByUserId",
            method:"get",
            params:param
        })
    },
    updateUserCommunity(param){
        return request({
            url:"/userCommunity/updateUserCommunity",
            method:"post",
            data:param
        })
    },
}