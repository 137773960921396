<template>
  <div id="index">
    <a-layout class="layout">
      <a-layout-sider
        v-if="!isFullScreen"
        v-model:collapsed="collapsed"
        theme="dark"
        width="190"
        class="side"
      >
        <Transition
          mode="out-in"
          name="logo"
        >
          <div
            v-if="!collapsed"
            style="width:210px;"
            class="logo"
          >
            <img src="@/assets/images/public/logo.png">
            <div>智慧广电充电桩</div>
          </div>
          <div
            v-else
            class="logo1"
          >
            <img src="@/assets/images/public/logo.png">
          </div>
        </Transition>
        <a-menu
          v-model:selectedKeys="current"
          mode="inline"
          :open-keys="openKeys"
          @select="menuSelect"
          @openChange="onOpenChange"
          @click="activePathFn"
        >
          <template
            v-for="menu in menus"
            :key="menu.menuName"
          >
            <sub-menu
              v-if="menu.child"
              :add="add"
              :title="menu.menuName"
              :menus="menu"
            />
            <a-menu-item
              v-else
              :key="menu.menuName"
              @click="add(menu)"
            >
              <template #icon>
                <icon-font :type="menu.icon" />
              </template>
              {{ menu.menuName }}
            </a-menu-item>
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header
          v-if="!isFullScreen"
          class="header"
        >
          <a-button
            type="text"
            style=""
            @click="toggleCollapsed"
          >
            <MenuUnfoldOutlined
              v-if="collapsed"
              style="font-size:20px;"
            />
            <MenuFoldOutlined
              v-else
              style="font-size:20px;"
            />
          </a-button>
          <a-breadcrumb>
            <a-breadcrumb-item
              v-for="e in activePath"
              :key="e"
            >
              {{ e }}
            </a-breadcrumb-item>
          </a-breadcrumb>
          <a-space style="position:absolute; right:30px;">
            <div class="welcome-message">欢迎，{{ userData.nickname }}</div>
            <a-tooltip>
              <template #title>
                回到首页
              </template>
              <a-button :disabled="homeUse"
                style="height:50px;"
                type="text"
                @click="add({menuName:'首页',menuUrl:'/cdz-index',key:'首页'})"
              >
                <template #icon>
                  <HomeOutlined style="font-size:20px;" />
                </template>
              </a-button>
            </a-tooltip>
            <a-popover title="选择系统主色调">
              <template #content>
                <div style="width:100%;height:100%;display:flex;justify-content:space-around;align-items:center;">
                  <div
                    style="height:20px;width:20px;background-color:#409eff;"
                    @click="setColor('#409eff')"
                  />
                  <div
                    style="height:20px;width:20px; background-color:#b486db;"
                    @click="setColor('#b486db')"
                  />
                  <div
                    style="height:20px;width:20px;background-color:#52c41a;"
                    @click="setColor('#52c41a')"
                  />
                </div>
              </template>
              <a-dropdown style="height:50px;">
                <BgColorsOutlined style="font-size:20px;" />
              </a-dropdown>
            </a-popover>
            <a-tooltip>
              <template #title>
                全屏
              </template>
              <a-button
                style="height:50px;"
                type="text"
              >
                <template #icon>
                  <FullscreenExitOutlined
                    v-if="isFullScreen"
                    style="font-size:20px;"
                    @click="exitFull"
                  />
                  <FullscreenOutlined
                    v-else
                    style="font-size:20px;"
                    @click="requestFullScreen"
                  />
                </template>
              </a-button>
            </a-tooltip>
            <a-tooltip>
              <template #title>
                修改密码
              </template>
              <a-button
                style="height:50px;"
                type="text"
                @click="openChangePwd"
              >
                <template #icon>
                  <KeyOutlined style="font-size:20px;" />
                </template>
              </a-button>
            </a-tooltip>
            <a-tooltip>
              <template #title>
                退出登录
              </template>
              <a-button
                style="height:50px;"
                type="text"
                @click="logout"
              >
                <template #icon>
                  <PoweroffOutlined style="font-size:20px;" />
                </template>
              </a-button>
            </a-tooltip>
            <a-tooltip>
              <template #title>
                用户信息
              </template>
              <a-button
                style="height:50px;"
                type="text"
                @click="isShowUserInfo=true"
              >
                <template #icon>
                  <UserOutlined style="font-size:20px;" />
                </template>
              </a-button>
            </a-tooltip>
            <a-modal
              v-model:visible="isChangePassword"
              :mask-closable="false"
              ok-text="修改"
              cancel-text="取消"
              :title="modifyPassTitle"
              @ok="handleChange"
            >
              <a-form ref="formPassword"
                autocomplete="off"
                :model="formState"
                class="login_input_box"
              >
                <a-form-item
                  name="oldPassword"
                  :rules="[{}]"
                >
                  <a-input-password
                    v-model:value="formState.oldPassword"
                    :maxlength="100"
                    size="large"
                    placeholder="请输入旧密码"
                  >
                    <template #prefix>
                      <key-outlined />
                    </template>
                  </a-input-password>
                </a-form-item>
                <a-form-item
                  name="newPassword"
                  :rules="[{required: true, message: '请输入密码'},
                            { min: 8, message: '密码长度不能小于8位'},
                            { max: 14, message: '密码长度不能大于14位'},
                            { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,14}/, message: '密码必须包含大小写字母，数字和特殊字符'}]">
                  <a-input-password
                    v-model:value="formState.newPassword"
                    :maxlength="100"
                    size="large"
                    placeholder="请输入新密码"
                  >
                    <template #prefix>
                      <key-outlined />
                    </template>
                  </a-input-password>
                </a-form-item>
                <a-form-item
                    name="confirmPassword"
                    :rules="[{ required: true, message: '请输入确认密码' },{ required: true, message: '两次密码不一致', pattern: new RegExp('^'+formState.newPassword + '$') }]"
                >
                  <a-input-password
                      v-model:value="formState.confirmPassword"
                      :maxlength="100"
                      size="large"
                      placeholder="请输入确认密码"
                  >
                    <template #prefix>
                      <key-outlined />
                    </template>
                  </a-input-password>
                </a-form-item>
              </a-form>
            </a-modal>
            <a-modal
              v-model:visible="isShowUserInfo"
              :mask-closable="false"
              :footer="null"
              :centered="true"
              title="用户信息"
            >
              <a-descriptions bordered>
                <a-descriptions-item
                    :span="3"
                    label="用户名"
                >
                  {{ userData.username }}
                </a-descriptions-item>
                <a-descriptions-item
                  :span="3"
                  label="昵称"
                >
                  {{ userData.nickname }}
                </a-descriptions-item>
                <a-descriptions-item
                  :span="3"
                  label="电话"
                >
                  {{ userData.phone }}
                </a-descriptions-item>
                <a-descriptions-item
                  :span="3"
                  label="邮箱"
                >
                  {{ userData.email }}
                </a-descriptions-item>
<!--                <a-descriptions-item
                  :span="3"
                  label="部门"
                >
                  {{ userData.section?.sectionName }}
                </a-descriptions-item>-->
                <a-descriptions-item
                  :span="3"
                  label="职位"
                >
                  {{ userData.job }}
                </a-descriptions-item>
                <a-descriptions-item
                  :span="3"
                  label="微信"
                >
                  {{ userData.wechat }}
                </a-descriptions-item>
              </a-descriptions>
            </a-modal>
          </a-space>
        </a-layout-header>
        <a-layout-content class="content">
          <a-tabs
            v-show="panes.length&&!isFullScreen"
            v-model:activeKey="activeKey"
            size="small"
            :tab-bar-gutter="5"
            style="background-color:#f1f2f6"
            hide-add
            type="editable-card"
            @edit="onEdit"
          >
            <a-tab-pane
              v-for="pane in panes"
              :key="pane.title"
              :closable="closable"
            >
              <template #tab>
                <div
                  v-directive:contextmenu
                  style="width:100%;height:100%;padding: 5px 16px;"
                  @contextmenu="handleContextMenu(pane)"
                >
                  {{ pane.title }}
                </div>
              </template>
            </a-tab-pane>
          </a-tabs>
          <div style="width:100%;flex:1;overflow: auto;">
            <router-view v-slot="{Component,route}">
              <component
                :is="Component"
                v-if="route.meta.noCache"
                :key="getkey(route)"
              />
              <keep-alive

                ref="currentVue"
              >
                <component
                  :is="Component"
                  v-if="!route.meta.noCache"
                  :key="getkey(route)"
                />
              </keep-alive>
            </router-view>
          </div>
        </a-layout-content>
        <!--        <a-layout-footer style="text-align: center;background-color:black;color:white;">
                  chrj Design ©2018 Created by vue
                </a-layout-footer>-->
      </a-layout>
    </a-layout>
  </div>
  <Contextmenu ref="contextmenu">
    <ContextmenuItem
      :disabled="disabled"
      @click="setIndex"
    >
      设为首页
    </ContextmenuItem>
  </Contextmenu>
</template>
<script setup>
import {computed, nextTick, onMounted, onUnmounted, provide, ref, watch, getCurrentInstance} from "vue";
import {mapState} from "vuex";
import {useRouter} from "vue-router";
import api from "@/api/public/user";
import {Contextmenu, ContextmenuItem, directive} from "v-contextmenu";
import "v-contextmenu/dist/themes/default.css";
import {
  BgColorsOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  HomeOutlined,
  KeyOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined,
  UserOutlined
} from '@ant-design/icons-vue';
import {message} from "ant-design-vue";

// const {} = mapState();
const openChangePwd = () => {
  formState.value = {
    newPassword: '',
    oldPassword: '',
  }
  isChangePassword.value = true
}

const getkey = (a) => {
  if (a.meta.cache !== false) {
    return a.fullPath;
  }
}
const logout = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('menus');
  sessionStorage.removeItem('permissionList');
  sessionStorage.removeItem('userData');
  router.replace({
    path: '/'
  })
}
provide('addMenuOutsideTab', (title, path, key) => {
  if (panes.value.findIndex(e => e.key === title) === -1) {
    panes.value.push({
      title,
      key: key || title,
      path,
      isOutsideTab: true,
      keyPath: [...openKeys.value, title]
    });
  }
  activeKey.value = title
});
provide('addMenuTab', (key) => {
  if (panes.value.findIndex(e => e.key === key) === -1) {
    panes.value.at(-1).keyPath = findMenuPathByKey(key, menus.value);
  }
  activeKey.value = key
});
/*const include = computed(() => {
  return panes.value.map(e => {
    let path = e.path;
    if (typeof path !== 'string') {
      path = path.path+"?"
      let query = path.query;
      query.
    }
    return path.slice(path.lastIndexOf("/") + 1)

  })
})*/
/*const getRouteKey = (route) => {
  return
}*/
const contextmenuTitle = ref("")
const disabled = ref(true)
const vDirective = directive
const userData = ref({});
const contextmenu = ref();
const handleContextMenu = (pane) => {
  disabled.value = pane.isOutsideTab;
  contextmenuTitle.value = pane.title;
};
const setIndex = () => {
  userData.value.homePage = contextmenuTitle.value;
  sessionStorage.setItem('userData', JSON.stringify(userData.value))
  api.setIndexPage(contextmenuTitle.value).then(e => {
    if (e) {
      message.success("设置成功")
    }
  });
};
window.addEventListener("resize", () => {
  if (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width) {
    isFullScreen.value = true
  } else {
    isFullScreen.value = false
  }
});
const SubMenu = {
  template: `
    <a-sub-menu :key="menus.menuName">
    <template #icon>
      <icon-font :type="menus.icon"></icon-font>
    </template>
    <template #title>{{ menus.menuName }}</template>
    <template v-for="menu in menus.child" :key="menu.menuName">
      <sub-menu v-if="menu.child" :add="add" :title="menu.menuName" :menus="menu"></sub-menu>
      <a-menu-item v-else @click="add(menu)" :key="menu.menuName">
        <template #icon>
          <icon-font :type="menu.icon"></icon-font>
        </template>
        {{ menu.menuName }}
      </a-menu-item>
    </template>
    </a-sub-menu>
  `,
  name: 'SubMenu',
  // must add isSubMenu: true 此项必须被定义
  isSubMenu: true,
  props: {
    // 解构a-sub-menu的属性，也就是文章开头提到的为什么使用函数式组件
    // 接收父级传递过来的菜单信息
    menus: {
      type: Object,
      default: () => ({}),
    },
    add: {
      type: Function,
      default: () => {
      }
    }
  },
};
const activeKey = ref('0');
const isChangePassword = ref(false)
const isShowUserInfo = ref(false)
const primaryColor = ref('#409eff')
provide('primaryColor', primaryColor)
const router = useRouter();
const isFullScreen = ref(false);
const requestFullScreen = () => {
  let element = document.documentElement;
  // 判断各种浏览器，找到正确的方法
  let requestMethod = element.requestFullScreen || //W3C
      element.webkitRequestFullScreen || //Chrome等
      element.mozRequestFullScreen || //FireFox
      element.msRequestFullScreen; //IE11
  if (requestMethod) {
    requestMethod.call(element);
  } else if (typeof window.ActiveXObject !== "undefined") {//for Internet Explorer
    message.error('浏览器不支持点击全屏')
  }
}
const exitFull = () => {
  // 判断各种浏览器，找到正确的方法
  let exitMethod = document.exitFullscreen || //W3C
      document.mozCancelFullScreen || //Chrome等
      document.webkitExitFullscreen || //FireFox
      document.webkitExitFullscreen; //IE11
  if (exitMethod) {
    exitMethod.call(document);
  } else if (typeof window.ActiveXObject !== "undefined") {//for Internet Explorer
    message.error('浏览器不支持点击全屏')
  }
}
const formPassword=ref(null);
let modifyPassTitle=ref("修改密码");
const handleChange = () => {
  if (formState.value.newPassword.trim().length < 6) {
    message.warning('密码不能小于6位')
  } else {
    formPassword.value.validate().then(() => {
        api.changePassword(formState.value).then(e => {
          message.success("修改成功");
          isChangePassword.value = false;
          //修改密码后退出登录
          logout();
        })
    }).catch(error => {
      console.log('error', "验证失败");
    });
  }
}
const formState = ref({
  newPassword: '',
  oldPassword: '',
})
const panes = ref([]);
const menus = ref(JSON.parse(sessionStorage.getItem('menus')));
const openRootKeys = computed(() => {
  let arr = [];
  menus.value.forEach(e => {
    arr.push(e.menuName)
  })
  return arr;
})
const current = ref(['2'])
const collapsed = ref(false)
const openKeys = ref([])
const currentPath = ref('')
const closable = computed(() => panes.value.length > 1)
const activePath = ref([])
const setColor = color => {
  api.setStyle(color).then(e => {
    if (e) {
      userData.value.style = color;
      sessionStorage.setItem('userData', JSON.stringify(userData.value))
      message.success('修改成功')
      window.less.modifyVars({
        "@primary-color": color,
      })
      primaryColor.value = color;
    }
  })

}
const activePathFn = ({key, keyPath}) => {
  let number = panes.value.findIndex(e => e.key === key);
  panes.value[number].keyPath ??= keyPath;
  activePath.value = keyPath;
}
const toggleCollapsed = () => {
  collapsed.value = !collapsed.value;
}
const currentVue = ref()

const onEdit = key => {
  let index = panes.value.findIndex(e => e.title === key);
  let item = panes.value.find(e => e.title === key);
  panes.value.splice(index, 1);
  if (index > 0) {
    activeKey.value = panes.value[index - 1].title;
  } else {
    activeKey.value = panes.value[index].title;
  }
}
const onOpenChange = e => {
  const latestOpenKey = e.find(key => openKeys.value.indexOf(key) === -1);
  if (openRootKeys.value.indexOf(latestOpenKey) === -1) {
    openKeys.value = e;
  } else {
    openKeys.value = latestOpenKey ? [latestOpenKey] : [];
  }
};
const add = (item) => {
  if (panes.value.findIndex(e => e.key === item.menuName) === -1) {
    panes.value.push({
      title: item.menuName,
      closable: true,
      key: item.menuName,
      path: item.menuUrl
    })
  }
  activeKey.value = item.menuName;
};
const menuSelect = ({keyPath}) => {
  openKeys.value = keyPath
}
const findMenuPathByKey = (key, arr) => {
  for (let item of arr) {
    if (item.menuName === key) {
      add(item);
      return [item.menuName];
    } else {
      if (item.child) {
        let fn1 = findMenuPathByKey(key, item.child);
        if (Array.isArray(fn1)) {
          fn1.unshift(item.menuName);
          return fn1;
        }
      }
    }
  }
}
let defaultPassword=false
let homeUse=ref(true);
onMounted(() => {
  let data = sessionStorage.getItem("userData");
  if (!menus?.value?.length) {
    message.warn("没有使用权限！")
    logout()
    return;
  }
  if (data) {
    userData.value = JSON.parse(data);
    let homePage = userData.value.homePage;
    window.less.modifyVars({
      "@primary-color": userData.value.style,
    })
    primaryColor.value = userData.value.style;
    let openKey = [];
    if (homePage && homePage !== "") {
      if (homePage === '首页') {
        add({menuName: '首页', menuUrl: '/cdz-index', key: '首页'})
        panes.value.at(-1).keyPath = ['首页'];
      }
      openKey = findMenuPathByKey(homePage, menus.value);
      panes.value.at(-1).keyPath = openKey;
    } else {
      add({menuName: '首页', menuUrl: '/cdz-index', key: '首页'})
      panes.value.at(-1).keyPath = ['首页'];
    }
    //判断是否默认密码
    defaultPassword=userData.value.defaultPassword;
    if(defaultPassword){
      isChangePassword.value=true;
      modifyPassTitle.value="默认密码登录请先修改密码";
    }
    //判断用户类型
    let userType=userData.value.userType;
    if(userType===1 || userType===2){
      homeUse.value=false;
    }
  } else {
    message.warn("登录信息已失效！")
    logout()
  }

})
watch(currentPath, newVal => {
  if (typeof newVal === 'string') {
    router.push({
      path: newVal,
    })
  } else {
    router.push(newVal)
  }
})
watch(activeKey, newVal => {
  let index = panes.value.findIndex(e => e.title === newVal);
  current.value = [newVal];
  currentPath.value = panes.value[index].path;
  let path = panes.value[index].keyPath;
  activePath.value = path;
  openKeys.value = path;
})
onUnmounted(() => {
  window.removeEventListener('resize', () => {
  });
})
</script>

<style lang="less">
#index {
  .ant-table-thead > tr > th{
    padding: 8px 8px !important;
  }
  .ant-table-tbody > tr > td{
    padding: 6px 8px !important;
  }
  .ant-table-tbody > tr > td .ant-btn {
    padding: 0 5px !important;
  }
  .ant-table-expand-icon-col{
    width: 20px;
  }
  .ant-card-head-title::before {
    content: 'a';
    margin: 5px;
    background: v-bind(primaryColor);
    color: v-bind(primaryColor);
  }

  .logo-enter-active {
    transition: opacity 1s ease;
  }

  .logo-leave-active {
    transition: opacity .1s ease;
  }

  .logo-enter-from,
  .logo-leave-to {
    opacity: 0;
  }

  .logo {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: center;

    > div {
      font-size: 20px;
      font-weight: bold;
    }

    > img {
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }
  }

  .ant-tabs-tab-remove {
    margin-left: unset !important;
  }

  .logo1 {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: center;

    > img {
      width: 22px;
      height: 22px;
    }
  }

  .ant-tabs-nav {
    margin: 0 !important;
    height: 40px !important;
  }

  .ant-layout-sider-children {
    //background-color: #fff;
    -webkit-box-shadow: 2px 0 8px 0 rgba(29, 35, 41, .02);
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, .02);
    z-index: 1;

  }

  .content {
    display: flex;
    flex-direction: column;


    > .ant-tabs {
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .12), 0 0 3px 1px rgba(0, 0, 0, .12);

      .ant-tabs-nav-wrap {
        background-color: #fff;
        padding: 3px 15px;
      }

      .ant-tabs-tab {
        background-color: white !important;
        padding: 0 !important;
        font-size: 12px !important;
      }

      .ant-tabs-tab-active {
        background-color: v-bind(primaryColor) !important;
        border: 1px solid #f1f2f6 !important;

        .ant-tabs-tab-btn {
          color: white !important;
        }
      }
    }
  }


  .layout {
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    min-width: 1536px;

    .header {
      height: 50px;
      line-height: 50px;
      background-color: #fff;
      padding: 0;
      -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
      box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
      z-index: 1;
      display: flex;
      align-items: center;
      position: relative;

    }

    .side {
      background-color: #ffffff;
      overflow-y: scroll;
    }

    .side::-webkit-scrollbar {
      display: none;
    }

    .content {
      background: #f2f5f9;

      //padding-top: 5px;
    }
  }


  .site-layout-background {
    background: #fff;
  }
  .welcome-message {
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #262626;
    text-align: center;
    height: 50px;
    line-height: 50px;
    padding-right: 20px;
  }
}
</style>
