import {createStore} from 'vuex'

export default createStore({
    state: {
        cache:['detection-kanban', 'server-kanban', 'core-network-kanban']
    },
    getters: {},
    mutations: {},
    actions: {
        cache(cache){
            this.state.cache=cache
        }
    },
    modules: {}
})
