const {onBeforeRouteLeave} = require("vue-router");

module.exports = {
    //定义最小混入
    data() {
        return {

        }
    },
}
