import {createApp} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import mixin from './mixins/common'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/less/common.less';
// import 'default-passive-events';
import dataV from '@jiaminghi/data-view'

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
// import 'ant-design-vue/dist/antd.variable.min.css'

import IconFont from './assets/fonts/icon'

createApp(App).use(dataV).use(store).use(Antd).use(Vue3VideoPlayer,{lang:'zh-CN'}).use(router).mixin(mixin).component('IconFont', IconFont).mount('#app')
